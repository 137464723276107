import React from 'react';

import { importAsset } from 'helper/helperMethods';

import styles from './styles.module.sass';

const LoadingPage = () => {
    return (
        <div className={styles.loadingPage}>
            <img src={importAsset('./images/icons/logo.svg')} alt='Logo' className={styles.logo} />
        </div>
    );
}

export default LoadingPage;