import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { isNull } from 'lodash';

import { importAsset } from 'helper/helperMethods';

import { MOBILE } from 'constants/deviceType';

import styles from './styles.module.sass';

const Technology = React.forwardRef((props, forwardRef) => {

    const isMobile = props.deviceType === MOBILE;

    const MAX_LINE_WIDTH = 539;
    const MIN_TECHNOLOGY_SECTOR_WIDTH = 1440;

    const cardsData = [
        {
            title: 'Business rules',
            secondaryTitle: 'Extra boost',
            description: 'Blacklisting and greylisting',
            linePlacement: 'top',
            lineText: 'Known patterns',
            rocketName: 'rocket-1',
            rocketClassName: 'top'
        },
        {
            title: 'Analytical models',
            secondaryTitle: 'Extra boost',
            description: 'Text mining',
            linePlacement: 'center',
            lineText: 'Complex patterns',
            rocketName: 'rocket-2',
            rocketClassName: 'center'
        },
        {
            title: 'Social Network analysis',
            secondaryTitle: 'Extra boost',
            description: 'Anomaly detection',
            linePlacement: 'bottom',
            lineText: 'Network & relationship patterns',
            rocketName: 'rocket-3',
            rocketClassName: 'bottom'
        }
    ]

    const contentRef = useRef();
    const cardsRef = useRef();
    const rocketPartsRef = useRef();

    const rocketPartOneRef = useRef();
    const rocketPartTwoRef = useRef();
    const rocketPartThreeRef = useRef();

    const lineRef = useRef();

    const [lineWidth, setLineWidth] = useState(0);

    const updateLineWidth = () => {
        if (isNull(contentRef.current) ||
            isNull(cardsRef.current) ||
            isNull(rocketPartsRef.current) ||
            isNull(lineRef.current)) return;

            window.innerWidth > MIN_TECHNOLOGY_SECTOR_WIDTH ? setLineWidth(MAX_LINE_WIDTH) : setLineWidth(contentRef.current.clientWidth - cardsRef.current.clientWidth - rocketPartsRef.current.clientWidth / 2 + lineRef.current.clientHeight / 2);
    }

    useEffect(() => {
        updateLineWidth();
        window.addEventListener('resize', updateLineWidth);
        rocketPartOneRef.current.addEventListener('load', updateLineWidth);
        rocketPartTwoRef.current.addEventListener('load', updateLineWidth);
        rocketPartThreeRef.current.addEventListener('load', updateLineWidth);
    }, []);

    return (
        <div className={styles.technology} ref={forwardRef}>
            <div className={styles.top}>
                <h1>Technology behind</h1>
                <p>Our approach to fraud detection involves the use of business expertise as well as the use of advanced analytical models. This approach maximizes the benefits of analytics - taking advantage of both known patterns and previously unknown correlations, resulting in better claim assessment.</p>
            </div>
            <div className={styles.content} ref={contentRef}>
                <div className={styles.cards} ref={cardsRef}>
                    {
                        cardsData.map((card) => {
                            return (
                                <div key={uuid()}>
                                    {isMobile && <img src={require(`../assets/images/${card.rocketName}.jpg`)} alt={card.rocketName} className={styles[card.rocketClassName]} />}
                                    <div className={styles.card}>
                                        <h2>{card.title}</h2>
                                        <h3>{card.secondaryTitle}</h3>
                                        <p>{card.description}</p>
                                        {
                                            !isMobile &&
                                            <div className={styles.line}>
                                                <p>{card.lineText}</p>
                                                <img src={importAsset(`./images/icons/${card.linePlacement}-line.svg`)} alt={`${card.linePlacement}-line`} style={{ width: `${lineWidth}px` }} ref={lineRef} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    !isMobile &&
                    <div className={styles.rocketParts} ref={rocketPartsRef}>
                        <img src={require('../assets/images/rocket-1.jpg')} alt='rocket-1' className={styles.top} ref={rocketPartOneRef} />
                        <img src={require('../assets/images/rocket-2.jpg')} alt='rocket-2' className={styles.center} ref={rocketPartTwoRef} />
                        <img src={require('../assets/images/rocket-3.jpg')} alt='rocket-3' className={styles.bottom} ref={rocketPartThreeRef} />
                    </div>
                }
            </div>
        </div>
    )
});

export default Technology;