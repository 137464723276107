
import React, { useRef } from 'react';

import ScrollIndicator from './ScrollIndicator';

import styles from './carousel.module.sass';

const Carousel = ({ count, className, children }) => {
    const target = useRef();

    return (
        <div>
            <div ref={target} className={`${styles.carouselContainer} ${className}`}>
                {children}
            </div>
            <ScrollIndicator count={count} ref={target} />
        </div>
    )
}

export default Carousel;